import React from 'react'
import styled from 'styled-components'
import Wrapper from 'src/components/shared/Wrapper'
import { useStaticQuery, graphql } from 'gatsby'
import NewsCard from 'src/components/shared/News/NewsCard'
import { ArchivePageNewsQueryQuery } from 'types/graphql-types'
import Title from 'src/components/shared/Title'

const Archives: React.FCX = (props) => {
  const archivePageNews = useStaticQuery<ArchivePageNewsQueryQuery>(graphql`
    query ArchivePageNewsQuery {
      allContentfulCorpNews(sort: { fields: published, order: DESC }) {
        edges {
          node {
            title
            slug
            published(formatString: "YYYY年MM月DD日")
            thumbnail {
              file {
                fileName
                url
              }
              contentful_id
            }
            category {
              slug
              title
            }
          }
        }
      }
    }
  `)

  return (
    <div className={`${props.className} page-wrapper`}>
      <Wrapper>
        <Title id="news" type={['color_main']} text="NEWS" sub="お知らせ一覧" />
        <div className="news-container">
          {archivePageNews.allContentfulCorpNews.edges.map((edge) => (
            <NewsCard
              slug={edge.node.slug}
              key={edge.node.slug}
              thumbnailId={edge.node.thumbnail.contentful_id}
              title={edge.node.title}
              category={edge.node.category}
              published={edge.node.published}
            />
          ))}
        </div>
      </Wrapper>
    </div>
  )
}

export default styled(Archives)`
  min-height: calc(100vh - 200px - 85px);
  color: #000;
  .news-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    &:after {
      content: '';
      width: 30%;
    }
  }
`
