import React from 'react'
import { Head } from 'src/components/layout/head'
import { DefaultLayout } from 'src/components/layout/default'
import Archives from 'src/components/pages/Archives'

const ArchivesPage: React.FCX = () => {
  return (
    <div className="archives-page">
      <Head title="お知らせ一覧 | 株式会社スムーズ" />
      <DefaultLayout>
        <Archives />
      </DefaultLayout>
    </div>
  )
}

export default ArchivesPage
