import React from 'react'
import styled from 'styled-components'
import { font, ls, mq, color } from 'src/lib/styles/variable'

type Props = {
  id?: string
  text: string
  sub?: string
  type?: ('color_white' | 'color_main' | 'jp_main')[]
}

const Title: React.FCX<Props> = (props) => {
  return (
    <h2 className={`${props.className} ${props.type?.join(' ')}`} id={props.id}>
      {props.text}
      {props.sub && (
        <span className={`${props.className}__sub`}>{props.sub}</span>
      )}
    </h2>
  )
}

export default styled(Title)`
  ${font(36)}
  ${ls(100)}
  font-family: 'Avenir';
  display: block;
  margin-bottom: 50px;
  font-weight: 900;

  ${mq('tab')} {
    ${font(27)}
    margin-bottom: 30px;
  }

  &__sub {
    ${font(14)}
    ${ls(75)};
    display: block;

    ${mq('tab')} {
      ${font(12)}
    }
  }

  &.color_white {
    color: #ffffff;
  }

  &.color_main {
    color: ${color.main};
  }

  &.jp_main {
    ${font(28)};
    font-weight: 600;
    font-family: 'A-OTF Midashi Go MB31 Pr6N', 'Hiragino Sans',
      'ヒラギノ角ゴシック', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3';
    ${mq('tab')} {
      ${font(22)};
      margin-bottom: 30px;
    }
  }
`
