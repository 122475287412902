import React from 'react'
import styled from 'styled-components'
import { mq, ma, font, color } from 'src/lib/styles/variable'
import { Link } from 'gatsby'
import { ContentfulImage } from '../Img/contentful'

type Props = {
  thumbnailId: string
  slug: string
  title: string
  category: {
    slug?: string
    title?: string
  }
  published: string
}

const NewsCard: React.FCX<Props> = (props) => {
  return (
    <div className={props.className}>
      <Link
        className={`${props.className}__link`}
        to={`/articles/${props.category.slug}/${props.slug}`}
      >
        <div className={`${props.className}__img`}>
          <ContentfulImage id={props.thumbnailId} />
        </div>
        <div className={`${props.className}__content`}>
          <time className={`${props.className}-date`}>{props.published}</time>
          <div className={`${props.className}-sentence-text-wrapper`}>
            <p className={`${props.className}-sentence-text u-text-bold`}>
              {props.title}
            </p>
          </div>
          <div className={`${props.className}__tag`}>
            {props.category.title}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default styled(NewsCard)`
  position: relative;
  width: 30%;
  border-radius: 5px;
  box-shadow:0px 0px 24px 2px rgba(0, 0, 0, .1);
  overflow: hidden;
  margin-bottom: 30px;

  ${mq('tab')}{
    width: 48%;
    &:last-child{
      display: none;
    }
  }

  ${mq('sp_wide')}{
    width: 100%;
    margin-bottom: 30px;
    &:last-child{
      display: block;
    }
  }

  &__link{
    width: 100%;
    height: 100%;
  }

  &__img{
    width: 100%;
    height: 250px;
    overflow: hidden;
    position: relative;
    .gatsby-image-wrapper {
      width: auto;
      height: 100%;
    }
  }

  &__content{
    ${ma};
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 25px;
    padding-left: 25px;
    ${mq('tab')}{
      padding-top: 30px;
      padding-bottom: 30px;
      padding-right: 20px;
      padding-left: 20px;
    }

    ${mq('sp_wide')}{
      width: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-right: 30px;
      padding-left: 30px;
    }
  }

  &-date{
    ${font(12)};
    color: #707070;
    position: relative;
    font-weight: normal;

    &:after{
      content:'';
      position: absolute;
      top: 50%;
      transform: translate(5px,-50%);
      width: 30px;
      height: 1px;
      background-color: #545554;
    }
  }

  &-sentence-text{
    margin-top: 10px;
    margin-bottom: 30px;
    color: #23292a;
    ${mq('tab')}{
      margin-top: 8px;
      margin-bottom: 22px;
    }
    ${mq('sp_wide')}{
      margin-top: 6px;
    }
  }

  &__tag{
    ${font(12)};
    position: absolute;
    bottom: 30px;
    font-weight: 600;
    color: ${color.main};
  }

  .c-button--for_news{
    ${font(16)};
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    font-weight: 600;
    width: 280px;
    line-height: 60px;
    height: 60px;

    ${mq('tab')}{
      ${font(14)};
      width: 220px;
      height: 50px;
      line-height: 50px;
      margin-bottom: 30px;
    }

    ${mq('sp')} {
      width: 100%;
    }
  }
`
